import { ChakraProvider } from '@chakra-ui/react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import theme from './config/theme';
import Fonts from './config/Fonts';

import Home from './components/Home';
import Hello from './components/Hello';

function App() {
  const location = useLocation();

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact component={Home} />
          <Route path="/hello" component={Hello} />
        </Switch>
      </AnimatePresence>
    </ChakraProvider>
  );
}

export default App;
