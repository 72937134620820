import { format } from 'date-fns';

const generate = date => {
  const time = format(date, 'HH:mm:ss');
  let bgColor, color, greeting;
  if (time >= '03:00' && time <= '12:00') {
    bgColor = 'gray.100';
    color = 'gray.800';
    greeting = 'Good Morning 🌤';
  } else if (time > '12:00' && time <= '18:00') {
    bgColor = 'gray.100';
    color = 'gray.800';
    greeting = 'Good Afternoon ☀';
  } else if (time > '18:00' && time <= '21:00') {
    bgColor = 'gray.800';
    color = 'gray.100';
    greeting = 'Good Evening 🌙';
  } else {
    bgColor = 'gray.800';
    color = 'gray.100';
    greeting = 'Good Night 💤';
  }
  return { bgColor, color, greeting, time };
};

export default generate;
