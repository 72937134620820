import React from 'react';
import { Button, Heading } from '@chakra-ui/react';
import { FaAngleDoubleLeft } from 'react-icons/fa';
import generate from '../lib/generate';

import Container from './layouts/Container';

const Hello = ({ history }) => {
  const [name, setName] = React.useState('');
  const date = new Date();

  React.useEffect(() => {
    const localName = localStorage.getItem('name');
    setName(localName);
  }, []);

  return (
    <Container bgColor={generate(date).bgColor} color={generate(date).color}>
      <Heading>Hi, {name} 👋</Heading>
      <Button
        onClick={() => history.goBack()}
        leftIcon={<FaAngleDoubleLeft />}
        mt={4}
        colorScheme="blue"
      >
        Go Back
      </Button>
    </Container>
  );
};

export default Hello;
