import React from 'react';
import {
  Button,
  Divider,
  Heading,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import generate from '../lib/generate';

import Container from './layouts/Container';

const Home = ({ history }) => {
  const [date, setDate] = React.useState(new Date());
  const [name, setName] = React.useState('');
  const toast = useToast();

  React.useEffect(() => {
    const localName = localStorage.getItem('name');
    if (localName) {
      setName(localName);
    }
  }, []);

  React.useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);

    return () => {
      clearInterval(timerID);
    };
  });

  const tick = () => {
    setDate(new Date());
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!name.trim().length) {
      return toast({
        title: `Your name can't be blank`,
        position: 'top',
        status: 'error',
        isClosable: true,
      });
    }
    localStorage.setItem('name', name);
    history.push('/hello');
  };

  return (
    <Container bgColor={generate(date).bgColor} color={generate(date).color}>
      <Heading>Hello, {generate(date).greeting}</Heading>
      <Text mt={4} fontWeight="500">
        Your current time is:
      </Text>
      <Text mt={2} fontSize="xl" fontWeight="600">
        {generate(date).time}
      </Text>
      <Divider my={5} w={64} />
      <Text mb={4}>Input your name below</Text>
      <form onSubmit={e => handleSubmit(e)}>
        <Input
          placeholder="Your name..."
          maxW={64}
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <Button type="submit" colorScheme="blue" mt={4} minW={64}>
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default Home;
