import { Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionFlex = motion(Flex);

const Container = ({ bgColor, color, children }) => (
  <MotionFlex
    as="main"
    direction="column"
    align="center"
    justify="center"
    px={5}
    minH="100vh"
    bg={bgColor}
    color={color}
    textAlign="center"
    animate="enter"
    exit="exit"
    initial="initial"
    variants={{
      initial: { opacity: 0, y: -10 },
      enter: { opacity: 1, y: 0 },
      exit: { opacity: 0, y: 10 },
    }}
  >
    {children}
  </MotionFlex>
);

export default Container;
